<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Edelweiss-Q2/Menu'
import Alert from '@/components/Alert'

export default {
	name: "EdelweissQ2_home",
	data() {
		return {
			isActiveAlert:false
		};
	},
	setup () {
		useMeta(
		{ 
			title: '艾德懷斯啤酒頂級奢華體驗',
			description: '2024/3/15-5/15至指定通路，購買艾德懷斯啤酒全系列，輸入序號就抽洲際住宿!!!' 
		})		
	},
	components: {
		Menu,
		Alert

	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		checkBrowser(){
			// console.log("checkBrowser")
			let vm=this
			var useragent = navigator.userAgent;
			var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(Instagram|Line|FBAN|FBAV)']
			var regex = new RegExp(`(${rules.join('|')})`, 'ig');
			if(Boolean(useragent.match(regex))){
				vm.isActiveAlert=true
			}else{
				location.href="https://heineken-event.webgene.com.tw/auth/redirect/edelweiss-q2"
			}
		},
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
